import React from "react";

import academicData from "./data/academic.json";  // Importing the JSON file directly

const AcademicSection = () => {
  return (
    <div className="academic-section" data-aos="zoom-in-up" data-aos-duration="2000" id="academics">
      <h2>{academicData.title}</h2>
      <div className="academic-list">
        {academicData.academics.map((item, index) => (
          <div className="academic-item" key={index}>
            <h3>{item.degree}</h3>
            <p className="institution-year">
              <strong>{item.institution}</strong>, {item.year}
            </p>
            <ul className="coursework-list">
              {item.coursework.map((course, index) => (
                <li key={index}>{course}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcademicSection;
