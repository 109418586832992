import React from "react";
import { FaInstagramSquare } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaGithubSquare } from "react-icons/fa";
import { CgMail } from "react-icons/cg";

function Contact() {
  return (
    <>
      <footer
        className="contact-footer"
        data-aos="zoom-in-up"
        data-aos-duration="2000"
      >
        <div className="container contact" id="contact">
          <div className="contact-icon">
            <a
              href="https://www.instagram.com/vishal_rajput_2_0/profilecard/?igsh=a203Y2Z1Z3kxcGF0"
              target="_blank"
              rel="noopener noreferrer"
              className="items"
            >
              <FaInstagramSquare className="icon" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100024476616887"
              target="_blank"
              rel="noopener noreferrer"
              className="items"
            >
              <CiFacebook className="icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/vishal-singh-niet/"
              target="_blank"
              rel="noopener noreferrer"
              className="items"
            >
              <CiLinkedin className="icon" />
            </a>
            <a
              href="https://x.com/VishalS93983847"
              target="_blank"
              rel="noopener noreferrer"
              className="items"
            >
              <FaSquareXTwitter className="icon" />
            </a>
            <a
              href="https://github.com/VishalNOV29"
              target="_blank"
              rel="noopener noreferrer"
              className="items"
            >
              <FaGithubSquare className="icon" />
            </a>
            <a
              href="mailto:vishalsingh841209@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="items"
            >
              <CgMail className="icon" />
            </a>
          </div>

          {/* New Section for Website Creator */}
          <div className="creator-info">
            <p>
              Created by <strong>Vishal Kumar Singh</strong>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Contact;
