import React, { useState } from "react";
import certificationsData from "./data/certification.json"; // Import your data from JSON

function Navbar() {
  const [modalData, setModalData] = useState(null); // State for modal data
  const closeModal = () => setModalData(null); // Function to close modal

  return (
    <>
      <div
        className="container nav_bar"
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <div className="left nav_items">
          <a href="#home" className="right" style={{ fontSize: "30px" }}>
            𝒱𝒾𝓈𝒽𝒶𝓁 𝒮𝒾𝓃𝑔𝒽
          </a>
        </div>
        <div className="right">
          <a href="#home" className="nav_items">
            Home
          </a>
          <a href="#project" className="nav_items">
            Projects
          </a>
          <a href="#academics" className="nav_items">
            Academics
          </a>
          <a
            href="#certificationsData"
            className="nav_items"
            onClick={() => {
              setModalData(certificationsData); // Set the certifications data to the modalData state
            }}
          >
            Certifications
          </a>
          <a href="#skills" className="nav_items">
            Skills
          </a>
          <a href="#contact" className="nav_items">
            Contact
          </a>
        </div>
      </div>

      {/* Modal */}
      {modalData && (
        <div
          className="modal-backdrop"
          onClick={closeModal}
          data-aos="zoom-in-up"
          data-aos-duration="1000"
        >
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header justify-content-center">
              <h2 className="modal-title" id="exampleModalLongTitle">
                {modalData.title || "Certifications"}
              </h2>
            </div>
            <div className="modal-body">
              <div className="certifications-list">
                {modalData.certifications &&
                modalData.certifications.length > 0 ? (
                  modalData.certifications.map((cert, index) => (
                    <div className="certification-item" key={index}>
                      <h3>
                        {cert.name}{" "}
                        <a
                          className="cert-link"
                          href={cert.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          (View Certificate)
                        </a>
                      </h3>
                      <p className="institution-year">
                        <strong>{cert.institution}</strong>, {cert.year}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="no-certifications">
                    <p>No certifications available.</p>
                  </div>
                )}
              </div>
            </div>

            <button
              className="btn btn-danger mt-3 close-btn mx-auto d-block"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
